import React, { useEffect, useState } from 'react';
import { getExportFields, getExportProducts } from '../../../../Shared/Data/ExportService';
import Checkbox from '../../../../Shared/Components/Checkbox/Checkbox';
import Button from '../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../Shared/Components/Button/enums';
import Modal from '../../../../Shared/Components/Modal/Modal';
import Loading from "../../../../Shared/Components/Loading/Loading";
import {InfinitySpin} from "react-loader-spinner";
import {LoadingIndicator} from "react-select/dist/declarations/src/components/indicators";

const ExportSubstances = () => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [exportFieldList, setExportFieldList] = useState<string[]>([]);
  const [selectableCheckboxFields, setSelectableCheckboxFields] = useState<string[]>([]);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  useEffect(() => {
    getExportFields().then((data: []) => {
      setSelectableCheckboxFields(data);
    });
  }, [showExportModal]);

  function handleCheckboxChange(id: string, checked: boolean) {
    if (checked) {
      setExportFieldList([...exportFieldList, id]);
    } else {
      setExportFieldList(exportFieldList.filter((field) => field !== id));
    }
  }

  const exportProducts = () => {
    setLoadingExport(true);
    getExportProducts(exportFieldList).then((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.xlsx');
      document.body.appendChild(link);
      link.click();
      setLoadingExport(false);
    });
  };

  let checkboxes;
  if (selectableCheckboxFields) {
    checkboxes = Object.entries(selectableCheckboxFields).map(([key, value]) => (
      <Checkbox
        key={key}
        boxChecked={exportFieldList.includes(key)}
        onToggleChecked={() => handleCheckboxChange(key, !exportFieldList.includes(key))}
        label={value}
        checkboxId={key}
        showLabel
      />
    ));
  }

  return (
    <>
      <Button
        title="Exporteren"
        theme={ButtonTypes.Solid}
        iconPath="/Icons/export.svg"
        onHandleClick={() => setShowExportModal(true)}
      />
          {showExportModal && checkboxes && (
        <Modal
          showModal={showExportModal}
          modalButtonRightText="Exporteren"
          modalTitle="Exporteren"
          modalButtonLeftText="Annuleren"
          onLeftAction={() => setShowExportModal(false)}
          onHandleClose={() => setShowExportModal(false)}
          onRightAction={() => exportProducts()}
          rightButtonEnabled={!loadingExport}
          modalDescription={!loadingExport ? "Welke velden wilt u exporteren?" : ''}
        >
          {loadingExport ? <InfinitySpin /> : checkboxes}
        </Modal>
      )}
    </>
  );
};

export default ExportSubstances;
