import React from 'react';
import { useParams } from 'react-router-dom';
import CollapsableInformationField from '../../../../Shared/Components/CollapsableContent/CollapsableInformationField';
import ComponentItem from '../../../../Shared/Components/CollapsableContent/SubComponents/ComponentItem';
import useSubstanceOrganisationData from '../../../../Shared/Hooks/useSubstanceOrganistion';
import Loading from '../../../../Shared/Components/Loading/Loading';
import { HospitalProductType } from '../../../../Models/Types/HospitalProductType';
import Location from './Location';

const OrganisationSpecificInformation = () => {
  const { id } = useParams();
  const { data, isLoading } = useSubstanceOrganisationData(id);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ul className="information-list">
        <CollapsableInformationField
            title="Organisatiespecifieke informatie"
            open
        >
            <ComponentItem item={{title: 'Eigen artikelnaam', description: (data as HospitalProductType)?.name}}/>
            <ComponentItem
                item={{title: 'Afval informatie', description: (data as HospitalProductType)?.waste_information_remarks}}
            />
            <strong>Eigen document: </strong><a href={(data as HospitalProductType)?.document.url} target="_blank" rel="noreferrer">{(data as HospitalProductType)?.document.filename}</a>
        </CollapsableInformationField>
        <CollapsableInformationField
            title="Gekoppelde locaties"
            open
        >
            {!!(data as HospitalProductType)?.locations &&
          (data as HospitalProductType).locations?.map(
            ({ amount, location: locationId, location_name, location_type }) => (
              <Location
                amount={amount}
                locationId={locationId}
                location_name={location_name}
                location_type={location_type}
              />
            ),
          )}
      </CollapsableInformationField>
    </ul>
  );
};

export default OrganisationSpecificInformation;
