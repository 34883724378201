import React, {useState} from 'react';

import './SubstancesRegisterFilterBar.scss';
import Select from 'react-select';
import {SelectItem} from '../../../../Models/Types/SelectItem';
import {FilterListItem} from '../../types';
import useCloset from '../../../../Shared/Hooks/useCloset';
import useDepartment from '../../../../Shared/Hooks/useDepartment';
import useRoom from '../../../../Shared/Hooks/useRoom';
import {Department, DepartmentResponse} from '../../../../Models/Types/Department';
import {Room, RoomResponse} from '../../../../Models/Types/Room';
import {Closet, ClosetResponse} from '../../../../Models/Types/Closet';
import useBem from '../../../../Hooks/useBem';
import {getAllDepartments} from '../../../../Shared/Data/DepartmentService';
import ExportSubstances from "../ExportSubstances/ExportSubstances";

type PropType = {
  results: number;
  onFilterListChange: (value: FilterListItem[]) => void;
};

const SubstancesRegisterList = (props: PropType) => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  const hospitalId = user?.hospital?.id;
  const [bemClassName] = useBem('filter-bar');
  const {results, onFilterListChange} = props;
  const filterList: FilterListItem[] = sessionStorage.filterList ? JSON.parse(sessionStorage.filterList) : [];
  const {isLoading: isLoadingDepartment, data: departmentResponse} = useDepartment(undefined, hospitalId);
  const {isLoading: isLoadingRooms, data: roomResponse} = useRoom(undefined, hospitalId);
  const {isLoading: isLoadingClosets, data: closetResponse} = useCloset(undefined, hospitalId);
  const [departmentOptions, setDepartmentOptions] = useState<SelectItem[] | undefined>(undefined);
  const [roomOptions, setRoomOptions] = useState<SelectItem[] | undefined>(undefined);
  const [closetOptions, setClosetOptions] = useState<SelectItem[] | undefined>(undefined);

  function updateFilterList(value: SelectItem, filter: string) {
    const filterPresent = filterList.find((filterItem) => filterItem.name === filter);

    if (filterPresent !== undefined) {
      if (value === null) {
        filterList.splice(filterList.indexOf(filterPresent), 1);
        onFilterListChange([...filterList]);
        sessionStorage.filterList = JSON.stringify([...filterList]);
        return;
      }

      filterPresent.filter = value;
    } else {
      filterList.push({name: filter, filter: value});
    }

    onFilterListChange([...filterList]);

    if (sessionStorage.filterList) {
      sessionStorage.filterList = JSON.stringify([...filterList]);
    } else {
      sessionStorage.setItem('filterList', JSON.stringify([...filterList]));
    }
  }

  function retrieveFilterValue(filter: string) {
    if (sessionStorage.filterList) {
      return JSON.parse(sessionStorage.filterList).find((filterItem: FilterListItem) => filterItem.name === filter)
        ? JSON.parse(sessionStorage.filterList).find((filterItem: FilterListItem) => filterItem.name === filter).filter
        : undefined;
    }
    return undefined;
  }

  function buildOptionsList(list: DepartmentResponse | RoomResponse | ClosetResponse | undefined, filter: string) {
    const options: SelectItem[] = [];

    function generateList() {
      list?.items.forEach((item: Department | Room | Closet) => {
        const {id, name} = item;
        options.push({value: id, label: name});
      });
    }

    switch (filter) {
      case 'department':
        generateList();
        setDepartmentOptions(options);
        break;
      case 'room':
        if (retrieveFilterValue('department')) {
          list?.items.forEach((item: Department | Room | Closet) => {
            const {id, name} = item;
            const room = (item as Room);
            let departmentId;
            if (typeof room.department === 'object') {
              departmentId = room.department?.id;
            } else {
              departmentId = room.department;
            }
            if (retrieveFilterValue('department').value === departmentId) {
              options.push({value: id, label: name});
            }
          });
        } else {
          list?.items.forEach((item: Department | Room | Closet) => {
            const {id, name} = item;
            const departmentLabel = (item as Room).department?.name
              ? `(Afdeling: ${(item as Room).department?.name})`
              : '';
            options.push({
              value: id,
              label: `${name} ${departmentLabel}`,
            });
          });
        }
        setRoomOptions(options);
        break;
      case 'closet':
        if (retrieveFilterValue('room')) {
          list?.items.forEach((item: Department | Room | Closet) => {
            const {id, name} = item;
            const closet = (item as Closet);
            let roomId;
            if (typeof closet.room === 'object') {
              roomId = closet.room?.id;
            } else {
              roomId = closet.room;
            }

            let addToOptions = true;
            if (retrieveFilterValue('room').value !== roomId) {
              addToOptions = false;
            }

            if (retrieveFilterValue('department') && closet.room) {
              let departmentId;
              if (typeof closet.room.department === 'object') {
                departmentId = closet.room.department?.id;
              } else {
                departmentId = closet.room.department;
              }

              if (retrieveFilterValue('department').value !== departmentId) {
                addToOptions = false;
              }
            }

            if (addToOptions) {
              options.push({
                value: id,
                label: name,
              });
            }
          });
        } else {
          list?.items.forEach((item: Department | Room | Closet) => {
            const {id, name} = item as Closet;
            options.push({
              value: id,
              label: `${name}`,
            });
          });
        }
        setClosetOptions(options);
        break;
      default:
        break;
    }
  }

  function buildUpdatedOptionsList(searchValue: string, filter: string) {
    const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
    const url = new URL(`${base}${filter}`);

    url.searchParams.append('page_limit', '2500');
    if (searchValue) {
      url.searchParams.append('search', searchValue);
    }

    if (filter === 'room' && retrieveFilterValue('department')) {
      url.searchParams.append('department', retrieveFilterValue('department').value);
    }
    if (filter === 'closet') {
      if (retrieveFilterValue('department')) {
        url.searchParams.append('department', retrieveFilterValue('department').value);
      }
      if (retrieveFilterValue('room')) {
        url.searchParams.append('room', retrieveFilterValue('room').value);
      }
    }

    getAllDepartments(url).then((response) => {
      switch (filter) {
        case 'department':
          buildOptionsList(response as DepartmentResponse, 'department');
          break;
        case 'room':
          buildOptionsList(response as RoomResponse, 'room');
          break;
        case 'closet':
          buildOptionsList(response as ClosetResponse, 'closet');
          break;
        default:
          break;
      }
    });
  }

  if (!isLoadingDepartment && departmentOptions === undefined) {
    buildOptionsList(departmentResponse as DepartmentResponse, 'department');
  }

  if (!isLoadingRooms && roomOptions === undefined) {
    buildOptionsList(roomResponse as RoomResponse, 'room');
  }

  if (!isLoadingClosets && closetOptions === undefined) {
    buildOptionsList(closetResponse as ClosetResponse, 'closet');
 }

  return (
    <div className="filter-bar">
      <div className={bemClassName('filters')}>
        <p>Filteren op:</p>
        {!isLoadingClosets && !isLoadingDepartment && !isLoadingRooms && (
          <>
            <Select
              classNamePrefix="select-box"
              placeholder="Afdeling / Locatie"
              options={departmentOptions}
              defaultValue={retrieveFilterValue('department')}
              onInputChange={(searchValue) => buildUpdatedOptionsList(searchValue, 'department')}
              onChange={(value) => updateFilterList(value as SelectItem, 'department')}
              isClearable
            />
            <Select
              classNamePrefix="select-box"
              placeholder="Ruimte"
              options={roomOptions}
              defaultValue={retrieveFilterValue('room')}
              onInputChange={(searchValue) => buildUpdatedOptionsList(searchValue, 'room')}
              onChange={(value) => updateFilterList(value as SelectItem, 'room')}
              isClearable
            />
            <Select
              classNamePrefix="select-box"
              placeholder="Kast"
              options={closetOptions}
              defaultValue={retrieveFilterValue('closet')}
              onInputChange={(searchValue) => buildUpdatedOptionsList(searchValue, 'closet')}
              onChange={(value) => updateFilterList(value as SelectItem, 'closet')}
              isClearable
            />
          </>
        )}
      </div>

      <div className={bemClassName('actions')}>
        <p>
          <span>{results}</span> resultaten
        </p>
        {sessionStorage.getItem('userLogin') ? null : (
          <ExportSubstances />
        )}
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
